export const apiEndPoint = {
	login: "login",
	change_password: "change_password",
	profile: "profile",
	client_details: "/details",
	job_work: {
		job_work: "/job_work",
		return: "/job_return",
		assign: "/job_assign_details",
		complete: "/job_complete",
		delivery: "/job_delivery_details",
		delivery_history: "/job_delivery_details/history",
		physical_delivery: "/physical_delivery",
		physical_delivery_history: "/physical_delivery/history",
		summary: "/job_work/summary",
		payment: "/payment_details",
		statement: "/payment_details/statement",
		invoice: "/job_work/invoice",
	},
	master: {
		permission: "/permission",
		user: {
			base: "/user",
			toggle: "/user/toggle/:id",
			reset: "/user/reset_password/:id",
		},
		user_permission: {
			base: "/user_permission/:mobile",
			not_assigned: "/user_permission/not_assigned/:id",
			create: "/user_permission",
			toggle: "/user_permission/toggle/:id",
			delete: "/user_permission/:id",
		},
		staff: "/staff",
		bank_upi_details: "/bank_upi_details",
		client: "/client",
		client_toggle: "/client/toggle",
		product: "/product",
		work_type: "/work_type",
	},
	list: {
		user: "/list/user",
		staff: "/list/staff",
		bank_upi_details: "/list/bank_upi_details",
		client: "/list/client",
		product: "/list/product",
		work_type: "/list/work_type",
		transporter: "/list/transporter",
		job_work: "/list/job_work",
		gst_state_code: "/list/gst_state_code",
	},
	dashboard: {
		states: "/dashboard/states",
		top_selling: "/dashboard/top_selling",
		processing: "/dashboard/processing",
		pending: "/dashboard/pending",
	},
	app_settings: {
		company: "/app_settings/company",
		notification: "/app_settings/notification",
		invoice: "/app_settings/invoice",
	},
};

export const pageRoutes = {
	login: "/session/signin",
	error: "/session/404",
	home: "/dashboard/default",
	client_home: "/",
	profile: "/profile",
	settings: "/settings",
	job_work: {
		list: "/job_work",
		details: "/job_work_details/:id?",
		delivery: "/job_delivery/:id?",
		physical_delivery: "/job_physical_delivery/:id?",
		summary: "/summary/:id?",
		payment: "/payments",
		statement: "/statement",
		invoice: "/invoice/:id?",
	},
	master: {
		permission: "/master/permission",
		user: "/master/user",
		user_permission: "/master/user_permission/:id",
		staff: "/master/staff",
		bank_upi_details: "/master/bank_upi_details",
		client: "/master/client",
		product: "/master/product",
		work_type: "/master/work_type",
	},
};
