import { createContext, useEffect, useReducer } from "react";
import { MatxLoading } from "../components";
import { axios } from "../services";
import AuthStorage from "../utils/authStorage";
import { apiEndPoint, pageRoutes } from "../constants/routes";
import { useNavigate } from "react-router-dom";

const initialState = {
	user: {
		id: "",
		name: "",
		image: "",
		user_type: "",
		is_password_default: true,
		show_password_popup: true,
		permissions: [],
		remember: false,
	},
	isInitialised: false,
	isAuthenticated: false,
};

const reducer = (state, action) => {
	switch (action.type) {
		case "INIT": {
			const { isAuthenticated, user } = action.payload;
			return { ...state, isAuthenticated, isInitialised: true, user };
		}

		case "LOGIN": {
			const { user } = action.payload;
			return { ...state, isAuthenticated: true, user };
		}

		case "LOGOUT": {
			return { ...state, isAuthenticated: false, user: initialState };
		}

		case "REGISTER": {
			const { user } = action.payload;

			return { ...state, isAuthenticated: true, user };
		}

		default:
			return state;
	}
};

const AuthContext = createContext({
	...initialState,
	method: "JWT",
	login: () => {},
	logout: () => {},
	register: () => {},
});

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const navigate = useNavigate();

	const login = async (mobile_no, password, remember) => {
		const response = await axios.post(apiEndPoint.login, { mobile_no, password });
		const { token, user } = response.data;
		user.permissions = [...Object.keys(user.permissions).map((group) => [...user.permissions[group]])].flat();
		user.remember = remember;
		user.show_password_popup = user.is_password_default;
        AuthStorage.setLoginDetails({mobile_no, password}, remember)
		AuthStorage.setStorageData(AuthStorage.STORAGEKEY.access_token, token, remember);
		AuthStorage.setStorageJsonData(AuthStorage.STORAGEKEY.userData, user, remember);

		dispatch({ type: "LOGIN", payload: { user } });
	};

	const register = async (email, username, password) => {
		const response = await axios.post("/api/auth/register", { email, username, password });
		const { user } = response.data;

		dispatch({ type: "REGISTER", payload: { user } });
	};

	const logout = () => {
		AuthStorage.deauthenticateUser();
		dispatch({ type: "LOGOUT" });
		navigate(pageRoutes.login);
	};

	const hasPermission = (permission, action) => {
		let flag = false;
		if (state.isAuthenticated) {
			const userPermissions = state.user.permissions || [];
			if (userPermissions && Array.isArray(userPermissions)) {
				const index = userPermissions.findIndex((row) => row.permission_name === permission);
				if (index > -1) {
					return userPermissions[index][action];
				}
			}
		}

		return flag;
	};

	const changeDefaultPasswordFlag = () => {
		const _user = AuthStorage.getStorageJsonData(AuthStorage.STORAGEKEY.userData);
		_user.show_password_popup = false;
		AuthStorage.setStorageJsonData(AuthStorage.STORAGEKEY.userData, _user, state.user.remember);
	};

	const toggleIsDefaultPassword = () => {
		const _state = { ...state };
		_state.user.is_password_default = false;
		dispatch({ type: "INIT", payload: { isAuthenticated: true, user: _state.user } });
	};

	useEffect(() => {
		if (AuthStorage.isUserAuthenticated(AuthStorage.STORAGEKEY.userData)) {
			dispatch({ type: "INIT", payload: { isAuthenticated: true, user: AuthStorage.getStorageJsonData(AuthStorage.STORAGEKEY.userData) } });
		} else {
			dispatch({ type: "INIT", payload: { isAuthenticated: false, user: initialState } });
		}
	}, []);

	// SHOW LOADER
	if (!state.isInitialised) return <MatxLoading />;

	return (
		<AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register, hasPermission, changeDefaultPasswordFlag, toggleIsDefaultPassword }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
