import axios from "axios";
import AuthStorage from "../utils/authStorage";
import * as CONFIG from "../constants/config";
import { pageRoutes } from "../constants/routes";

const instance = axios.create({
	baseURL: CONFIG.API_BASE_URL,
});

// handle errors
instance.interceptors.request.use(
	(config) => {
		let token = AuthStorage.getToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		if (config.method === "post" || config.method === "put") {
			if (config.data instanceof FormData) {
				config.headers["Content-Type"] = "multipart/form-data";
			}
		}

		return config;
	},
	(error) => Promise.reject(error)
);

// handle errors
instance.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		if (error.response.status === 401) {
			window.location.href = pageRoutes.login;
		}
		return Promise.reject({
			errors: error.response.data.error,
			status: error.response.data.status,
		});
	}
);

export const get = (url, params) => {
	return instance.get(url, {
		params,
	});
};

export const post = (url, data) => {
	return instance.post(url, data);
};

export const put = (url, data) => {
	return instance.put(url, data);
};

export const destroy = (url) => {
	return instance.delete(url);
};
