import { Card, Box, styled } from "@mui/material";

const CardRoot = styled(Card)({
	height: "100%",
	padding: "20px 24px",
});

const CardTitle = styled("div")(({ subtitle }) => ({
	fontSize: "1rem",
	fontWeight: "500",
	textTransform: "capitalize",
	marginBottom: !subtitle && "16px",
}));

const SimpleCard = ({ children, title, subtitle, sx }) => {
	return (
		<CardRoot elevation={6} sx={sx}>
			<CardTitle subtitle={subtitle}>{title}</CardTitle>
			{subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
			{children}
		</CardRoot>
	);
};

export default SimpleCard;
