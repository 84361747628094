class AuthStorage {
	static STORAGEKEY = {
		userData: "userData",
		access_token: "access_token",
		user_id: "user_id",
		name: "name",
		user_details: "4wxknRzTbo",
	};

	static setStorageData(key, data, keepMeLoggedIn) {
		localStorage.setItem(key, data);
	}

	static setStorageJsonData(key, data, keepMeLoggedIn) {
		localStorage.setItem(key, JSON.stringify(data));
	}

	static getStorageData(key) {
		return localStorage.getItem(key);
	}

	static getStorageJsonData(key) {
		const data = localStorage.getItem(key);
		return JSON.parse(data);
	}

	static getToken() {
		return localStorage.getItem(this.STORAGEKEY.access_token);
	}

	static getUserId() {
		return localStorage.getItem(this.STORAGEKEY.user_id);
	}

	static hasPermission(permission, action) {
		let flag = false;

		if (this.isUserAuthenticated()) {
			const userPermissions = this.getStorageJsonData(this.STORAGEKEY.userData).permissions || [];
			if (userPermissions && Array.isArray(userPermissions)) {
				const index = userPermissions.findIndex((row) => row.permission_name === permission);
				if (index > -1) {
					return userPermissions[index][action];
				}
			}
		}
		return flag;
	}

	// Function to set a session cookie
	static setCookie(cname, cvalue) {
		document.cookie = cname + "=" + cvalue + ";path=/";
	}

	// Function to get a cookie
	static getCookie(cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(";");
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	static setLoginDetails(login_details, remember) {
		if (!remember) {
            this.deleteCookie(this.STORAGEKEY.user_details);
		} else {
			this.setCookie(this.STORAGEKEY.user_details, JSON.stringify({ i: btoa(login_details.mobile_no), w: btoa(login_details.password) }));
		}
	}

	static getLoginDetails() {
		let data = this.getCookie(this.STORAGEKEY.user_details);
		try {
			data = JSON.parse(data);
			return { mobile_no: atob(data.i), password: atob(data.w) };
		} catch (error) {
			return { mobile_no: "", password: "" };
		}
		// return JSON.parse(data);
	}

	// Function to delete a cookie
	static deleteCookie(cname) {
		document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	}

	// static userType = this.getStorageJsonData(this.STORAGEKEY.userData)?.user_type || "";

	static isUserAuthenticated() {
		return localStorage.getItem(this.STORAGEKEY.access_token) !== null;
	}

	static deauthenticateUser() {
		Object.keys(this.STORAGEKEY).forEach((key) => {
			this.deleteKey(key);
		});
	}

	static deleteKey(key) {
		localStorage.removeItem(key);
		sessionStorage.removeItem(key);
	}
}

export default AuthStorage;
