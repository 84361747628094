import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthGuard from "../auth/AuthGuard";
import { authRoles } from "../auth/authRoles";
import Loadable from "../components/Loadable";
import MatxLayout from "../components/MatxLayout/MatxLayout";
import { pageRoutes } from "../constants/routes";
import * as CONFIG from "../constants/config";
import useAuth from "../hooks/useAuth";

// session pages
const NotFound = Loadable(lazy(() => import("./sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("./sessions/JwtLogin")));

// Jobwork
const JobworkList = Loadable(lazy(() => import("./Jobwork/JobworkList")));
const JobworkDetail = Loadable(lazy(() => import("./Jobwork/JobWorkDetail")));
const JobWorkDelivery = Loadable(lazy(() => import("./Jobwork/JobWorkDelivery")));
const JobWorkPhysicalDelivery = Loadable(lazy(() => import("./Jobwork/JobWorkPhysicalDelivery")));
const JobWorkSummary = Loadable(lazy(() => import("./Jobwork/Summary")));
// const JobWorkInvoice = Loadable(lazy(() => import("./Jobwork/JobWorkInvoice")));
const JobPaymentList = Loadable(lazy(() => import("./Jobwork/Payment/JobWorkPaymentList")));
const Statement = Loadable(lazy(() => import("./Jobwork/Payment/Statement")));

// Masters
const BankUpiList = Loadable(lazy(() => import("./Master/BankUpi/BankUpiList")));
const ClientList = Loadable(lazy(() => import("./Master/Client/ClientList")));
const ProductList = Loadable(lazy(() => import("./Master/Product/ProductList")));
const StaffList = Loadable(lazy(() => import("./Master/Staff/StaffList")));
const UserList = Loadable(lazy(() => import("./Master/UserDetails/UserList")));
const UserPermission = Loadable(lazy(() => import("./Master/UserDetails/UserPermission")));
const WorkTypeList = Loadable(lazy(() => import("./Master/WorkType/WorkTypeList")));
const Permissions = Loadable(lazy(() => import("./Master/Permissions")));

// Other
const Profile = Loadable(lazy(() => import("./Profile")));
const Settings = Loadable(lazy(() => import("./Settings")));

// echart page
// const AppEchart = Loadable(lazy(() => import('./charts/echarts/AppEchart')));

// dashboard page
const Analytics = Loadable(lazy(() => import("./dashboard/Analytics")));

export const NavigationRoutes = () => {
	const { user, hasPermission } = useAuth();

	const routes = [
		{
			element: (
				<AuthGuard>
					<MatxLayout />
				</AuthGuard>
			),
			children: [
				{
					path: pageRoutes.home,
					element: <Analytics />,
					auth: authRoles.admin,
				},

				...(hasPermission(CONFIG.PERMISSIONS.JOB_WORK, CONFIG.ACTION.VIEW)
					? [
							{ path: pageRoutes.job_work.list, element: <JobworkList /> },
							{ path: pageRoutes.job_work.details, element: <JobworkDetail /> },
							{ path: pageRoutes.job_work.summary, element: <JobWorkSummary /> },
							// { path: pageRoutes.job_work.invoice, element: <JobWorkInvoice /> },
					  ]
					: []),
				...(hasPermission(CONFIG.PERMISSIONS.PHYSICAL_DELIVERY, CONFIG.ACTION.VIEW)
					? [{ path: pageRoutes.job_work.physical_delivery, element: <JobWorkPhysicalDelivery /> }]
					: []),
				...(hasPermission(CONFIG.PERMISSIONS.DELIVERY, CONFIG.ACTION.VIEW)
					? [{ path: pageRoutes.job_work.delivery, element: <JobWorkDelivery /> }]
					: []),
				...(hasPermission(CONFIG.PERMISSIONS.PAYMENT, CONFIG.ACTION.VIEW)
					? [
							{ path: pageRoutes.job_work.payment, element: <JobPaymentList /> },
							{ path: pageRoutes.job_work.statement, element: <Statement /> },
					  ]
					: []),
				...(hasPermission(CONFIG.PERMISSIONS.BANK, CONFIG.ACTION.VIEW)
					? [{ path: pageRoutes.master.bank_upi_details, element: <BankUpiList /> }]
					: []),
				...(hasPermission(CONFIG.PERMISSIONS.CLIENT, CONFIG.ACTION.VIEW) ? [{ path: pageRoutes.master.client, element: <ClientList /> }] : []),
				...(hasPermission(CONFIG.PERMISSIONS.PRODUCT, CONFIG.ACTION.VIEW) ? [{ path: pageRoutes.master.product, element: <ProductList /> }] : []),
				...(hasPermission(CONFIG.PERMISSIONS.STAFF, CONFIG.ACTION.VIEW) ? [{ path: pageRoutes.master.staff, element: <StaffList /> }] : []),
				...(hasPermission(CONFIG.PERMISSIONS.USER, CONFIG.ACTION.VIEW) ? [{ path: pageRoutes.master.user, element: <UserList /> }] : []),
				...(hasPermission(CONFIG.PERMISSIONS.USER, CONFIG.ACTION.VIEW)
					? [{ path: pageRoutes.master.user_permission, element: <UserPermission /> }]
					: []),
				...(hasPermission(CONFIG.PERMISSIONS.WORK_TYPE, CONFIG.ACTION.VIEW)
					? [{ path: pageRoutes.master.work_type, element: <WorkTypeList /> }]
					: []),
				...(hasPermission(CONFIG.PERMISSIONS.PERMISSION, CONFIG.ACTION.VIEW)
					? [{ path: pageRoutes.master.permission, element: <Permissions /> }]
					: []),
				{ path: pageRoutes.profile, element: <Profile /> },
				...(user.user_type === CONFIG.E_USER_TYPE.admin ? [{ path: pageRoutes.settings, element: <Settings /> }] : []),
			],
		},

		// session pages route
		{ path: pageRoutes.error, element: <NotFound /> },
		{ path: pageRoutes.login, element: <JwtLogin /> },

		{ path: "/", element: <Navigate to={pageRoutes.home} /> },
		{ path: "*", element: <NotFound /> },
	];

	const clientRoutes = [
		{
			element: (
				<AuthGuard>
					<MatxLayout />
				</AuthGuard>
			),
			children: [
				{
					path: pageRoutes.client_home,
					element: <JobworkList />,
				},
				{ path: pageRoutes.job_work.list, element: <JobworkList /> },
				{ path: pageRoutes.job_work.summary, element: <JobWorkSummary /> },
				{ path: pageRoutes.profile, element: <Profile /> },
				{ path: pageRoutes.job_work.statement, element: <Statement /> },
			],
		},
		{ path: "*", element: <NotFound /> },
	];

	const content = useRoutes(user !== null && user.user_type === CONFIG.E_USER_TYPE.client ? clientRoutes : routes);

	return content;
};

export default NavigationRoutes;
