import { CssBaseline } from "@mui/material";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import NavigationRoutes from "./pages/routes";
import { Toaster } from "react-hot-toast";

const App = () => {
	return (
		<SettingsProvider>
			<AuthProvider>
				<MatxTheme>
					<Toaster position="top-center" reverseOrder={false} />
					<CssBaseline />
                    <NavigationRoutes/>
				</MatxTheme>
			</AuthProvider>
		</SettingsProvider>
	);
};

export default App;
