import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";

const CustomDateInput = ({ id, name, label, fullWidth, placeholder, autoFocus, defaultValue, value, onChange, error, helperText }) => {
	const [dateValue, setDateValue] = useState(value || moment().format("YYYY-MM-DD"));
	const onKeyDown = (event) => {
		var keyCode = event.keyCode || event.which;

		// Allow backspace (8), tab (9), hyphen (45), and numbers (48-57) numeric keypad (96-105) and up (38) and down (40) arrow keys
		if (
			// keyCode === 8 ||
			keyCode === 9 ||
			keyCode === 45 ||
			// (keyCode >= 48 && keyCode <= 57) ||
			// (keyCode >= 96 && keyCode <= 105) ||
			keyCode === 38 ||
			keyCode === 40
		) {
			return true;
		} else {
			// Prevent the default action (typing) if the key is not allowed
			event.preventDefault();
			return false;
		}
	};

	const onBlur = (e) => {
		if (e.target.value === "") {
			setDateValue(defaultValue);
			onChange({
				target: {
					name: e.target.name,
					value: defaultValue,
				},
			});
		}
	};

	useEffect(() => {
		setDateValue(value);

		return () => {
			setDateValue(moment().format("YYYY-MM-DD"));
		};
	}, [value]);

	return (
		<TextField
			autoFocus={autoFocus}
			id={id}
			name={name}
			label={label}
			fullWidth={fullWidth}
			placeholder={placeholder}
			type="date"
			onKeyDown={onKeyDown}
			value={dateValue}
			onChange={onChange}
			onBlur={onBlur}
			error={error}
			helperText={helperText}
		/>
	);
};

CustomDateInput.defaultProps = {
	fullWidth: false,
	placeholder: "",
	autoFocus: false,
	error: false,
	helperText: "",
	defaultValue: moment().format("YYYY-MM-DD"),
};

CustomDateInput.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	fullWidth: PropTypes.bool,
	placeholder: PropTypes.string,
	autoFocus: PropTypes.bool,
	defaultValue: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	helperText: PropTypes.string,
};

export default CustomDateInput;
